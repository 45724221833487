<template>
    <header class="page-title">
        <h1><i class="bi" :class="icon"></i> {{ title }}</h1>
        <div>
            <slot/>
        </div>
    </header>
</template>

<script>
export default {
    name: 'PageTitle',
    props: {
        icon: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
