import { isNud } from '@/utilities/typing'
import Employee from '@/models/entities/employee';
import Pref from '@/models/enums/pref';
import SchoolType from '@/models/enums/school/school-type';
import ShootingType from '@/models/enums/school/shooting-type';

/**
 * 学校マスタ エンティティ
 */
class School {
    school_id;
    school_name;
    shortened_name;
    school_type;
    shooting_type;
    employee;
    pref;
    address;
    tel;
    contact_person;
    note;
    is_deleted;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_id = properties.school_id;
        this.school_name = properties.school_name;
        this.shortened_name = properties.shortened_name;
        this.school_type = properties.school_type;
        this.shooting_type = properties.shooting_type;
        this.employee = new Employee(properties.employee);
        this.pref = properties.pref;
        this.address = properties.address;
        this.tel = properties.tel;
        this.contact_person = properties.contact_person;
        this.note = properties.note;
        this.is_deleted = properties.is_deleted;
    }

    get pref_display() {
        return Pref.get(this.pref) ?? '';
    }
    get display_address() {
        return this.address ?? '';
    }
    get full_address() {
        return this.pref_display + this.display_address;
    }
    get school_type_display() {
        return SchoolType.get(this.school_type);
    }
    get shooting_type_display() {
        return ShootingType.get(this.shooting_type);
    }
}

export default School;
