import Enum from "../enum";

/**
 * 社員 カメラマンフラグ
 */
class IsPhotographer extends Enum {
    static PHOTOGRAPHER = 1; // カメラマン
    static NOT_PHOTOGRAPHER = 0; // カメラマンではない

    static values() {
        return {
            [this.PHOTOGRAPHER]: 'カメラマン',
            [this.NOT_PHOTOGRAPHER]: 'カメラマンではない',
        }
    }
}

export default IsPhotographer;
