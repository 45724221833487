import { isNud } from '@/utilities/typing'
import School from '@/models/entities/school';
import SalesType from '@/models/enums/event/sales-type';
import GroupPhotoType from '@/models/enums/event/group-photo-type';

/**
 * 行事マスタ エンティティ
 */
class Event {
    event_id;
    school;
    event_month;
    event_day;
    event_name;
    sales_type;
    group_photo_type;
    album_grade;
    shooting_grade;
    note;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.event_id = properties.event_id;
        this.school = new School(properties.school);
        this.event_month = properties.event_month;
        this.event_day = properties.event_day;
        this.event_name = properties.event_name;
        this.event_type = properties.event_type;
        this.sales_type = properties.sales_type;
        this.group_photo_type = properties.group_photo_type;
        this.album_grade = properties.album_grade;
        this.shooting_grade = properties.shooting_grade;
        this.note = properties.note;
    }

    get sales_type_display() {
        return SalesType.get(this.sales_type);
    }
    get group_photo_type_display() {
        return GroupPhotoType.get(this.group_photo_type);
    }

    get event_date() {
        return `${this.event_month}/${this.event_day}`
    }
}

export default Event;
