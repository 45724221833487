import Enum from '../enum'

/**
 * 行事 集合写真
 */
class GroupPhotoType extends Enum {
    static NONE = 0; // なし
    static ONLY_PHOTO = 1; // あり(販売なし)
    static FREE = 2; // 自由販売
    static PURCHASE = 3; // 買取販売

    static values() {
        return {
            [this.NONE]: 'なし',
            [this.ONLY_PHOTO]: 'あり(販売なし)',
            [this.FREE]: '自由販売',
            [this.PURCHASE]: '買取販売',
        }
    }
}

export default GroupPhotoType;