import Enum from '../enum'

/**
 * 学校 撮影種別
 */
class ShootingType extends Enum {
    static SCHOOL = 1; // 学校
    static EVENT = 2; // イベント撮影

    static values() {
        return {
            [this.SCHOOL]: '学校',
            [this.EVENT]: 'イベント撮影',
        }
    }
}

export default ShootingType;