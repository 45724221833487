import Enum from '../enum'

/**
 * 行事 販売
 */
class SalesType extends Enum {
    static NONE = 0; // なし
    static SALES = 1; // あり

    static values() {
        return {
            [this.NONE]: 'なし',
            [this.SALES]: 'あり',
        }
    }
}

export default SalesType;