<template>
    <select
        :value="modelValue"
        @input="fire($event)"
        class="form-select"
        :required="required"
    >
        <template v-if="empty_option">
            <option value="" selected>{{ empty_option }}</option>
        </template>
        <template v-for="option in options" :key="option.key">
            <option :value="option[option_key]">{{ option[option_label] }}</option>
        </template>
    </select>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        modelValue: {
        },
        //SELECTで選択した結果を返す型
        model_type: {
            type: String,
            default: 'int' // int, float, string
        },
        options: {
            type: Array
        },
        option_key: {
            type: String,
            default: 'key'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        empty_option: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue'
    ],
    methods: {
        fire($event) {
            if ($event.target.value === null || $event.target.value === '') {
                this.$emit('update:modelValue', null);
            } else if (this.model_type === 'int' || this.model_type === 'integer') {
                this.$emit('update:modelValue', parseInt($event.target.value));
            } else if (this.model_type === 'float') {
                this.$emit('update:modelValue', parseFloat($event.target.value));
            } else {
                this.$emit('update:modelValue', String($event.target.value));
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
