<template>
    <div class="inline-loader"><div></div><div></div><div></div> {{message}}</div>
</template>

<script>
export default {
    name: 'InlineLoader',
    props: {
        message: {
            type: String,
            default: '読込中です...'
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    45% {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
        opacity: 0.7;
    }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    45% {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
        opacity: 0.7;
    }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.inline-loader {
    padding: 5px;
    font-size: 15px;
    line-height: 15px;
    color: #666;
}

.inline-loader > div:nth-child(1) {
    -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.inline-loader > div:nth-child(2) {
    -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.inline-loader > div:nth-child(3) {
    -webkit-animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.inline-loader > div {
    background-color: #333;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 5px;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    display: inline-block;
}
</style>
