import Enum from './enum'

/**
 * 都道府県
 */
class Pref extends Enum {
    static HOKKAIDO  = 1;
    static AOMORI    = 2;
    static IWATE     = 3;
    static MIYAGI    = 4;
    static AKITA     = 5;
    static YAMAGATA  = 6;
    static FUKUSHIMA = 7;
    static IBARAKI   = 8;
    static TOCHIGI   = 9;
    static GUNMA     = 10;
    static SAITAMA   = 11;
    static CHIBA     = 12;
    static TOKYO     = 13;
    static KANAGAWA  = 14;
    static NIIGATA   = 15;
    static TOYAMA    = 16;
    static ISHIKAWA  = 17;
    static FUKUI     = 18;
    static YAMANASHI = 19;
    static NAGANO    = 20;
    static GIFU      = 21;
    static SHIZUOKA  = 22;
    static AICHI     = 23;
    static MIE       = 24;
    static SHIGA     = 25;
    static KYOTO     = 26;
    static OSAKA     = 27;
    static HYOGO     = 28;
    static NARA      = 29;
    static WAKAYAMA  = 30;
    static TOTTORI   = 31;
    static SHIMANE   = 32;
    static OKAYAMA   = 33;
    static HIROSHIMA = 34;
    static YAMAGUCHI = 35;
    static TOKUSHIMA = 36;
    static KAGAWA    = 37;
    static EHIME     = 38;
    static KOCHI     = 39;
    static FUKUOKA   = 40;
    static SAGA      = 41;
    static NAGASAKI  = 42;
    static KUMAMOTO  = 43;
    static OITA      = 44;
    static MIYAZAKI  = 45;
    static KAGOSHIMA = 46;
    static OKINAWA   = 47;

    static values() {
        return {
            [this.HOKKAIDO]: '北海道',
            [this.AOMORI]: '青森県',
            [this.IWATE]: '岩手県',
            [this.MIYAGI]: '宮城県',
            [this.AKITA]: '秋田県',
            [this.YAMAGATA]: '山形県',
            [this.FUKUSHIMA]: '福島県',
            [this.IBARAKI]: '茨城県',
            [this.TOCHIGI]: '栃木県',
            [this.GUNMA]: '群馬県',
            [this.SAITAMA]: '埼玉県',
            [this.CHIBA]: '千葉県',
            [this.TOKYO]: '東京都',
            [this.KANAGAWA]: '神奈川県',
            [this.NIIGATA]: '新潟県',
            [this.TOYAMA]: '富山県',
            [this.ISHIKAWA]: '石川県',
            [this.FUKUI]: '福井県',
            [this.YAMANASHI]: '山梨県',
            [this.NAGANO]: '長野県',
            [this.GIFU]: '岐阜県',
            [this.SHIZUOKA]: '静岡県',
            [this.AICHI]: '愛知県',
            [this.MIE]: '三重県',
            [this.SHIGA]: '滋賀県',
            [this.KYOTO]: '京都府',
            [this.OSAKA]: '大阪府',
            [this.HYOGO]: '兵庫県',
            [this.NARA]: '奈良県',
            [this.WAKAYAMA]: '和歌山県',
            [this.TOTTORI]: '鳥取県',
            [this.SHIMANE]: '島根県',
            [this.OKAYAMA]: '岡山県',
            [this.HIROSHIMA]: '広島県',
            [this.YAMAGUCHI]: '山口県',
            [this.TOKUSHIMA]: '徳島県',
            [this.KAGAWA]: '香川県',
            [this.EHIME]: '愛媛県',
            [this.KOCHI]: '高知県',
            [this.FUKUOKA]: '福岡県',
            [this.SAGA]: '佐賀県',
            [this.NAGASAKI]: '長崎県',
            [this.KUMAMOTO]: '熊本県',
            [this.OITA]: '大分県',
            [this.MIYAZAKI]: '宮崎県',
            [this.KAGOSHIMA]: '鹿児島県',
            [this.OKINAWA]: '沖縄県',
        }
    }
}

export default Pref;
