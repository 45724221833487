import { isNud } from '@/utilities/typing';
import Role from '@/models/enums/employee/role';
import IsPhotographer from '@/models/enums/employee/is-photographer';

/**
 * 社員 エンティティ
 */
class Employee {
    photographer_id;
    photographer_type;
    photographer_name;
    shortened_name;
    is_photographer;
    login_key;
    priority;
    roles;


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.photographer_id = properties.photographer_id;
        this.photographer_type = properties.photographer_type;
        this.photographer_name = properties.photographer_name;
        this.shortened_name = properties.shortened_name;
        this.is_photographer = properties.is_photographer ?? IsPhotographer.NOT_PHOTOGRAPHER;
        this.login_key = properties.login_key;
        this.priority = properties.priority;
        this.roles = [];
        if ('roles' in properties) {
            this.roles = properties.roles.map(val => parseInt(val, 10));
        }
    }

    get roles_display() {
        return Role.join(this.roles);
    }
}

export default Employee;
