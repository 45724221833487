<template>
    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <page-title
            :title="`${school.school_name} 行事一覧`"
            icon="bi-calendar-event"
        >
            <button-back
                :to="{name: 'SchoolList'}"
                text="学校一覧へ"
            ></button-back>
        </page-title>

        <section class="section">
            <table class="table table-striped mb-4" style="table-layout:fixed; width: 100%">
                <thead>
                    <tr class="table-primary">
                        <th class="text-center col-md-5">行事名</th>
                        <th class="text-center col-md-3">標準実施日</th>
                        <th class="text-center col-md-2">販売</th>
                        <th class="text-center col-md-3">集合写真</th>
                        <th class="text-center col-md-3">アルバム学年</th>
                        <th class="text-center col-md-2">撮影学年</th>
                        <th class="col-md-3">備考</th>
                        <th class="col-md-3"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="event, index in events" :key="event">
                        <td class="">
                            {{ event.event_name }}
                        </td>
                        <td class="text-center">
                            {{ event.event_date }}
                        </td>
                        <td class="text-center">
                            {{ event.sales_type_display }}
                        </td>
                        <td class="text-center">
                            {{ event.group_photo_type_display }}
                        </td>
                        <td class="text-center">
                            {{ event.album_grade ?? '-' }}
                        </td>
                        <td class="text-center">
                            {{ event.shooting_grade ?? '-' }}
                        </td>
                        <td class="col-md-3">
                            <div class="note">{{ event.note }}</div>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="編集..."
                                icon="bi-box-arrow-up-left"
                                @click="openModal(event, index)"
                            ></button-exec>
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <div class="ms-2">
                <button-exec
                    text="新規登録..."
                    icon="bi-plus-lg"
                    @click="openModal()"
                ></button-exec>
            </div>
        </section>

        <!-- モーダル -->
        <div v-if="modal">
            <transition name="fade">
                <div v-if="modal">
                    <div class="modal-backdrop show"></div>
                    <div class="modal" tabindex="-1" style="display:block">
                        <div class="modal-dialog" style="max-width: 70% !important">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">
                                        {{ school.school_name }} {{ modal_title }}
                                    </h4>
                                    <button type="button" class="btn-close" @click="cancelEdit"></button>
                                </div>
                                <div class="modal-body">
                                    <form @submit.prevent="save" id="modal_form">
                                        <div class="row mb-5">
                                            <div class="form-group col-md-6">
                                                <label>行事名</label>
                                                <form-input
                                                    v-model="modal_event.event_name"
                                                    :required="true"
                                                ></form-input>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>標準実施日</label>
                                                <div class="input-group">
                                                    <form-input-number
                                                        v-model="modal_event.event_month"
                                                        min="1"
                                                        max="12"
                                                        step="1"
                                                        :required="true"
                                                    ></form-input-number>
                                                    <span class="input-group-text">月</span>
                                                        <form-input-number
                                                            v-model="modal_event.event_day"
                                                            min="1"
                                                            max="31"
                                                            step="1"
                                                            :required="true"
                                                        ></form-input-number>
                                                    <span class="input-group-text">日</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-5">
                                            <div class="form-group col-md-6">
                                                <label>販売</label>
                                                <form-select
                                                    v-model="modal_event.sales_type"
                                                    :options="sale_types"
                                                    empty_option="-- 選択 --"
                                                    :required="true"
                                                ></form-select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>集合写真</label>
                                                <form-select
                                                    v-model="modal_event.group_photo_type"
                                                    :options="group_photo_types"
                                                    empty_option="-- 選択 --"
                                                    :required="true"
                                                ></form-select>
                                            </div>
                                        </div>
                                        <div class="row mb-5">
                                            <div class="form-group col-md-6">
                                                <label>撮影学年</label>
                                                <form-input
                                                    v-model="modal_event.shooting_grade"
                                                ></form-input>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>アルバム対象学年</label>
                                                <form-input-number
                                                    v-model="modal_event.album_grade"
                                                    min="1"
                                                    max="9"
                                                    step="1"
                                                ></form-input-number>
                                            </div>
                                        </div>
                                        <div class="row mb-5">
                                            <div class="form-group col-md-12">
                                                <label>備考</label>
                                                <form-input
                                                    v-model="modal_event.note"
                                                ></form-input>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer d-flex justify-content-between">
                                    <button-exec-create
                                        v-if="!modal_event.event_id"
                                        size="btn-lg"
                                        form="modal_form"
                                    ></button-exec-create>
                                    <button-exec-update
                                        v-else
                                        size="btn-lg"
                                        form="modal_form"
                                    ></button-exec-update>
                                    <button-exec-delete
                                        v-if="modal_event.event_id"
                                        size="btn-lg"
                                        @click="$refs.confirm_remove.show()"
                                    ></button-exec-delete>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </template>
    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import InlineLoader from '@/components/tools/InlineLoader';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import School from '@/models/entities/school';
import Event from '@/models/entities/event';
import SalesType from '@/models/enums/event/sales-type';
import GroupPhotoType from '@/models/enums/event/group-photo-type';
import FormInput from '@/components/forms/FormInput.vue';
import FormInputNumber from '@/components/forms/FormInputNumber.vue';
import FormSelect from '@/components/forms/FormSelect.vue';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate.vue';

/**
 * TODO リファクタ案（片岡）
 *
 * # 現状
 * ## モーダルオープン時
 * modal_event = event 参照渡し
 * original_event = event 値渡し
 * ## 編集キャンセル時
 * event = original_event
 * ## 保存時
 * event を POST
 *
 * # 変更案
 * ## モーダルオープン時
 * modal_event = event 値渡し
 * ## 保存時
 * modal_event を POST
 * 完了時 event = 戻り値
 */

export default {
    name: 'PartnerList',
    components: {
        PageTitle,
        InlineLoader,
        ConfirmDialog,
        FormInput,
        FormInputNumber,
        FormSelect,
        ButtonBack,
        ButtonExec,
        ButtonExecDelete,
        ButtonExecCreate,
        ButtonExecUpdate,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            school_id: parseInt(this.$route.params.id, 10),
            school: new School(),
            events: [],
            modal: false,
            modal_event: new Event(),
            original_event: new Event(),
            modal_index: null,
            modal_title: null,

            sale_types: SalesType.options(),
            group_photo_types: GroupPhotoType.options(),
        }
    },
    mounted() {
        this.fetchSchool();
        this.fetchEventList();
    },
    methods: {
        fetchSchool() {
            this.loading++;
            this.$http.get(`/schools/${this.school_id}`)
            .then(response => {
                this.school = new School(response.data);
            })
            .finally(() => {
                this.loading--;
            });
        },
        fetchEventList() {
            this.loading++;

            this.events.splice(0);

            this.$http.get(`/schools/${this.school_id}/events`)
            .then(response => {
                for (let row of response.data) {
                    this.events.push(new Event(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        openModal(event = null, index = null) {
            this.modal = true;
            this.modal_index = index;
            if (event) {
                this.modal_title = '行事編集';
                this.modal_event = event;
                // オブジェクトを値渡しに変える処理
                this.original_event = JSON.stringify(event);
                this.original_event = JSON.parse(this.original_event);

            } else {
                this.modal_title = '行事登録';
                this.modal_event = new Event();
            }

        },
        cancelEdit() {
            if (this.modal_event.event_id) {
                let target_index = this.events.findIndex(event => event.event_id === this.original_event.event_id);

                this.events[target_index].event_month = this.original_event.event_month;
                this.events[target_index].event_day = this.original_event.event_day;
                this.events[target_index].event_name = this.original_event.event_name;
                this.events[target_index].sales_type = this.original_event.sales_type;
                this.events[target_index].group_photo_type = this.original_event.group_photo_type;
                this.events[target_index].album_grade = this.original_event.album_grade;
                this.events[target_index].shooting_grade = this.original_event.shooting_grade;
                this.events[target_index].note = this.original_event.note;

                this.original_event = new Event();
            }
            this.closeModal();
        },
        closeModal() {
            this.modal = false;
        },
        save() {
            if (this.modal_event.event_id) {
                // 編集
                this.startScreenLoading();
                this.$http.put(`/schools/${this.school_id}/events/${this.modal_event.event_id}`, this.modal_event)
                .then(() => {
                    this.fetchEventList();
                    this.showMessage('編集しました');
                    this.modal_event = new Event();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            } else {
                // 登録
                this.startScreenLoading();
                this.modal_event.school = this.school;

                this.$http.post(`/schools/${this.school_id}/events`, this.modal_event)
                .then(() => {
                    this.fetchEventList();
                    this.showMessage('登録しました');
                    this.modal_event = new Event();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            }
        },
        // 削除
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/schools/${this.school_id}/events/${this.modal_event.event_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.events.splice(this.modal_index, 1);
            })
            .finally(() => {
                this.endScreenLoading();
                this.closeModal();
            });
        }
    }
}
</script>

<style scoped>
    .note {
        overflow: hidden;
        white-space: nowrap;
        /* width: 0; */
        text-overflow: ellipsis;
    }
</style>
