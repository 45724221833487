<template>
    <button :type="type" class="btn" :class="[color, size]">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'ButtonExecUpdate',
    props: {
        text: {
            type: String,
            default: '保存',
        },
        icon: {
            type: String,
            default: 'bi-save',
        },
        type: {
            type: String,
            default: 'submit',
        },
        color: {
            type: String,
            default: 'btn-primary'
        },
        size: {
            type: String,
            default: '',
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
