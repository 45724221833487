import Enum from '../enum'

/**
 * 学校 学校種別
 */
class SchoolType extends Enum {
    static PRE_SCHOOL = 1; // 幼・保
    static ELEMENTARY_SCHOOL = 2; // 小学校
    static JUNIOR_HIGH_SCHOOL = 3; // 中学校
    static HIGH_SCHOOL = 4; // 高等学校
    static COMBINED = 5; // 中高一貫校
    static OTHER = 9; // その他

    static values() {
        return {
            [this.PRE_SCHOOL]: '幼・保',
            [this.ELEMENTARY_SCHOOL]: '小学校',
            [this.JUNIOR_HIGH_SCHOOL]: '中学校',
            [this.HIGH_SCHOOL]: '高等学校',
            [this.COMBINED]: '中高一貫校',
            [this.OTHER]: 'その他',
        }
    }
}

export default SchoolType;